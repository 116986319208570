<script lang="ts" setup>
  import type {setup} from "~/interfaces/setup";
  useHead({
    title: 'SES Tracker - Onboarding',
    meta: [{
      name: 'description',
      content: 'A web application for managing email statuses, assigning applications to clients, creating email templates, and managing customer information. Streamline your workflow with our comprehensive email and client management tools.'
    }]
  })
  const steps = useState<setup[]>('steps-form', ()=>{
    return [
        {
          id: 1,
          step: "01",
          title: 'Step 1',
          description: 'This is the first step',
          current: true,
          data:{}
        },
      {
        id: 2,
        step: "02",
        title: 'Step 2',
        description: 'This is the first step',
        current: true,
        data:{}
      },
      {
        id: 3,
        step: "03",
        title: 'Step 3',
        description: 'This is the first step',
        current: true,
        data:{}
      }
      ]

  })

</script>
<template>
  <div class="px-4 md:px-10 xl:px-[150px] py-4 md:py-10 lg:py-20 flex flex-col min-h-screen gap-5 md:gap-10 lg:gap-20">
    <div class="relative">
      <img src="~/assets/svg/logo.svg" alt="logo" class="w-full max-w-80 mx-auto block dark:hidden" />
      <img src="~/assets/svg/logo-dark.svg" alt="logo" class="w-full max-w-80 mx-auto hidden dark:block" />
      <DarkModeButton class="absolute top-2 right-0" />
    </div>
    <div>
      <h2 class="text-3xl font-bold mb-2 text-center">
        <span>Step</span>
        <span class="ml-1 ">{{steps.find((item: setup)=> item?.current )?.step}} <span class="text-slate-400 dark:text-gray-700 -ml-1">/03</span></span>
      </h2>
<!--      <UProgress size="sm" class="w-full max-w-64 mx-auto" :value="33.33 * steps[steps.length - 1 ].id" />-->
      <div class="h-1 w-full max-w-64 bg-gray-200 dark:bg-gray-700 rounded mx-auto ">
        <div class="steps-load h-1 bg-primary rounded " :style="`--step-current: ${steps.find((item: setup)=> item?.current )?.id || 1}; --steps: 3`"></div>
      </div>

    </div>
    <div class="w-full max-w-[1212px] flex justify-center mx-auto">
      <slot></slot>
    </div>
  </div>
</template>