<script lang="ts" setup>
const colorMode = useColorMode()

const isDark = computed({
  get () {
    return colorMode.value === 'dark'
  },
  set () {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
  }
})
</script>

<template>
  <div class="w-10 h-10 grid place-items-center cursor-pointer">
    <UButton
        :icon="isDark ?  'i-heroicons-sun' : 'i-heroicons-moon'"
        variant="link"
        aria-label="Theme"
        @click="isDark = !isDark"
    />
  </div>
</template>